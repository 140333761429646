import React, { FC, useEffect, useState } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Stack,
  styled,
  Typography,
  Box,
  Container,
} from "@mui/material";
import { colors } from "../../shared/const";
// import { supabase } from "../../supabaseClient";
import { Elinfrastrukturfil_jsonData, Solcellefil_jsonData } from '../../shared/data';

import { useLocation } from "react-router-dom";

export const BadgesBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive?: boolean;
}>(({ isActive = false }) => ({
  opacity: isActive ? 1 : 0.5,
  userSelect: isActive ? "auto" : "none",
  cursor: isActive ? "auto" : "default",
}));

const Side13: FC = () => {
  const [data, setData] = useState<any>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [aktivitet, setAktivitet] = useState<string | null>(null);
  const [vaesentlighed, setVaesentlighed] = useState<string | null>(null);
  const [opmaerksomhed, setOpmaerksomhed] = useState<string | null>(null);

  const [dbantal, setDbantal] = useState<any>(null);
  const [dbantaltotal, setDbantalTotal] = useState<any>(null);

  let table = "";
  let txttype = "Alle projekttyper";
  if (aktivitet) {
    if (aktivitet === "Solcelleprojekt") {
      table = "Solcellefil";
      txttype = "Solcelleprojekter";
    } else if (aktivitet === "Elinfrastrukturprojekt") {
      table = "Elinfrastrukturfil";
      txttype = "Elinfrastrukturprojekter";
    }
  }



const filterAndSortData = (data: any[]) => {
  return data.filter(item =>
    item["Simplificering"] === opmaerksomhed &&
    item["signifikanslabel"] === vaesentlighed
  ).sort((a, b) => {
    if (a.Simplificering > b.Simplificering) return 1;
    if (a.Simplificering < b.Simplificering) return -1;
    return 0;
  });
}

const processData = (result: any[]) => {
  if (result) {
    setData(result);   
    setDbantal(result.length);    
  }
}

const getAllData = async (dbtype: string | undefined) => {
  let result: any[] = [];
  if (table == "Solcellefil") {
    result = filterAndSortData(Solcellefil_jsonData);
  } else if (table == "Elinfrastrukturfil") {
    result = filterAndSortData(Elinfrastrukturfil_jsonData);
  }  
  processData(result);
};

  // const getAllData = async (table: string | undefined) => {
  //   if (table) {
  //     const result = await supabase
  //       .from(table)
  //       .select()
  //       .eq("Simplificering", opmaerksomhed)
  //       .eq("signifikanslabel", vaesentlighed)
  //       .order("Simplificering", { ascending: true });
  //     if (result && result.data) {
  //       setData(result.data);
  //       setDbantal(result.data.length);
  //     }
  //     const resultcount = await supabase.from(table).select();
  //     if (resultcount && resultcount.data) {
  //       setDbantalTotal(resultcount.data.length);
  //     }
  //   }
  // };

  useEffect(() => {
    setAktivitet(queryParams.get("aktivitet"));
    setVaesentlighed(queryParams.get("vaesentlighed"));
    setOpmaerksomhed(queryParams.get("opmaerksomhed"));
    if (aktivitet !== null && data === null) {
      getAllData(table);
    }
  }, [data, location.search, aktivitet, table]);

  return (
    <Box sx={{ backgroundColor: colors.lightGrey }}>
      <Container
        maxWidth="lg"
        sx={{ backgroundColor: colors.lightGrey, height: "100%" }}
      >
        {!data ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 8 }}>
                <Typography variant="h2">
                  <div>{opmaerksomhed}</div>
                </Typography>
                <Typography variant="h4" style={{ marginTop: "20px" }}>
                  <p>
                    Tidligere {txttype} har medført <i>"{vaesentlighed}"</i> for{" "}
                    {opmaerksomhed}.
                  </p>
                  <p>
                    Dette er nævnt i {dbantal} ud af {dbantaltotal} tidligere{" "}
                    {txttype} rapporter.
                  </p>
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ mb: 2 }}>
                <Typography component={"h5"}>Rapporttitel</Typography>{" "}
              </Grid>
              <Grid item xs={4} sx={{ mb: 2 }}>
                <Typography component={"h5"}>Citat</Typography>
              </Grid>
              <Grid item xs={2} sx={{ mb: 2 }}>
                <Typography component={"h5"}>Afbødning</Typography>
              </Grid>
              <Grid item xs={2} sx={{ mb: 2 }}>
                <Typography component={"h5"}>Download</Typography>
              </Grid>

              <Stack spacing={2} sx={{ width: "100%" }}>
                {data.map(
                  (
                    i: {
                      rapportlabel: string;
                      citat: string;
                      afbødningslabel: string;
                      signifikanslabel: string;
                      linkurl: string;
                    },
                    index: number
                  ) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{ p: 2, display: "flex", alignItems: "center" }}
                    >
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {i.rapportlabel}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">{i.citat}</Typography>{" "}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {i.afbødningslabel}
                        </Typography>{" "}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          <a
                            target={"_blank"}
                            rel={"noreferrer"}
                            href={i.linkurl}
                            style={{
                              color: colors.grey,
                              fontSize: "14px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "95%",
                            }}
                          >
                            Download rapport
                          </a>
                        </Typography>{" "}
                      </Grid>
                    </Card>
                  )
                )}
              </Stack>
            </Grid>
          </>
        )}
      </Container>
    </Box>
  );
};
export default Side13;
