import { FC } from "react";
import { Box, Container, Typography } from "@mui/material";
import { colors } from "../../shared/const";

const Introduktion: FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: colors.lightGrey,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: colors.lightGrey,
          height: "100%",
          color: colors.greyDark,
        }}
      >
        <Typography variant={"h5"} color={colors.greyDark} mt={2}>
          Introduktion til CAUSA-værktøjet
        </Typography>
        <Typography variant={"body2"}>
          Det vil være en stor hjælp i miljøvurderingsarbejdet, hvis vi lettere
          kan få overblik over vurderinger af væsentlighed af påvirkninger og
          afværgetiltag i tidligere miljøvurderinger. Det vil især understøtte
          arbejdet med at screene, afgrænse og skrive rapporter – og også være
          en mulighed for at kvalificere dialogen på tværs af aktører og
          offentligheden.
        </Typography>
        <Typography variant={"body2"}>
          Der er i dag ikke et godt værktøj til at få overblik over
          vurderingerne. DREAMS-projektet har derfor udarbejdet CAUSA-værktøjet,
          som et pilotprojekt på et værktøj, der kan skabe overblikket, og her
          på siden har du mulighed for at prøve værktøjet af ved at søge
          informationer frem.
        </Typography>
        <Typography variant={"h5"} color={colors.greyDark} mt={2}>
          Om CAUSA
        </Typography>
        <Typography variant={"body2"}>
          CAUSA er en database, der viser sammenhænge mellem miljøvurderinger,
          påvirkninger, væsentlighed og afværge. Det indebærer en strukturering
          af kausalitet og klassificeringer af indholdet i miljøvurderinger.
          Baggrundsdokumenter kan findes på{" "}
          <a
            href={"https://dreamsproject.dk/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            https://dreamsproject.dk/
          </a>
        </Typography>
        <Typography variant={"h5"} color={colors.greyDark} mt={2}>
          Anvendelse og begrænsninger
        </Typography>
        <Typography variant={"body2"}>
          CAUSA-værktøjet er en prototype, der kan give et indtryk af værdien og
          mulighederne i at kunne tilgå overblik over vurderingerne i tidligere
          rapporter.{" "}
        </Typography>
        <Typography variant={"body2"}>
          Prototypens anvendelse er dog begrænset af, at databasen er baseret på
          en begrænset mængde af rapporter (19 stk), at databasen ikke er
          opdateret, og at indholdet bærer præg af, at formuleringer omkring
          væsentlighed mv. i tidligere rapporter ofte ikke er entydige.
        </Typography>
        <Typography variant={"h5"} color={colors.greyDark} mt={2}>
          Fremtiden for CAUSA-værktøjet
        </Typography>
        <Typography variant={"body2"}>
          En fremtidig opdatering af CAUSA vil kræve en strukturering og
          standardisering af dele af miljørapporter og miljøkonsekvensrapporter,
          så indholdet kan læses maskinelt. DREAMS-projektet vil give et forslag
          til en standardisering af ikke-tekniske resuméer.
        </Typography>
      </Container>
    </Box>
  );
};

export default Introduktion;
