import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./App.css";
import { theme } from "./shared/theme";
import Header from "./components/ui/Header";
import NewEnvironmentalAssessment from "./pages/Search";
import Side10 from "./pages/Side10";
import Side10_2 from "./pages/Side10_2";
import Side10_3 from "./pages/Side10_3";
import Side13 from "./pages/Side13";
import Side13_3 from "./pages/Side13_3";
import Introduktion from "./pages/Introduktion";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Header />
      <Routes>
        <Route path="/" element={<Introduktion />} />
        <Route
          path="/search"
          element={<NewEnvironmentalAssessment />}
        />
        
        <Route path="/side10" element={<Side10 />} />
        <Route path="/side10_2" element={<Side10_2 />} />
        <Route path="/side10_3" element={<Side10_3 />} />
        <Route path="/side13" element={<Side13 />} />
        <Route path="/side13_3" element={<Side13_3 />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
