import React, { FC, useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  styled,
  Typography,
  Box,
  Container,
  ThemeProvider,
  Checkbox,
} from "@mui/material";
import { colors } from "../../shared/const";
import { side10_3_fil_jsonData } from "../../shared/data";

import { useLocation } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          paper: {
            "&.MuiPaper-root": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "0",
          },
        },
      },
      MUIDataTableHeadRow: {
        styleOverrides: {
          root: {
            borderBottom: `solid 1px ${colors.lightGrey400}`,
          },
        },
      },
    },
  });

export const BadgesBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive?: boolean;
}>(({ isActive = false }) => ({
  opacity: isActive ? 1 : 0.5,
  userSelect: isActive ? "auto" : "none",
  cursor: isActive ? "auto" : "default",
}));

const Side10_3: FC = () => {
  const [data, setData] = useState<any>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [aktivitet, setAktivitet] = useState<string | null>(null);
  const [fokus, setFokus] = useState<string | null>(null);
  const [dbantal, setDbantal] = useState<any>(null);
  const [haendelserSortOrder, setHaendelserSortOrder] = useState<
    null | boolean
  >(true);
  const [opmaerksomhedspunktSortOrder, setOpmaerksomhedspunktSortOrder] =
    useState<null | boolean>(null);
  const [vaesentlighedSortOrder, setVaesentlighedSortOrder] = useState<
    boolean | null
  >(null);

  let dbtype = "";
  let txttype = "Alle projekttyper";
  let fokustype = "";
  let txtfokus = "Alle fokusområder";

  if (aktivitet) {
    if (aktivitet === "Solcelleprojekt") {
      dbtype = "q5580";
      txttype = "Solcelleprojekter";
    } else if (aktivitet === "Elinfrastrukturprojekt") {
      dbtype = "q702";
      txttype = "Elinfrastrukturprojekter";
  //    setDbantal(4);
    } else if (aktivitet.includes("(under udarbejdelse)")) {
      dbtype = "ikke klar";
      txttype = "Projekt " + aktivitet;
    }
  
    if (fokus) {
      if (fokus === 'Natur') {
        txtfokus = "natur";
        fokustype = "Natur";
      } else if (fokus === 'Sundhed') {
        txtfokus = "sundhed";
        fokustype = "Sundhed";
      }
    }
  }



  const getAllData = async (dbtype: string | undefined) => {
    if (dbtype) {
      if (aktivitet === "Solcelleprojekt") {
        setDbantal(9);
      } else if (aktivitet === "Elinfrastrukturprojekt") {
        setDbantal(4);
      }  
      const result = side10_3_fil_jsonData.filter(item => 
        item["type"] === dbtype         
      ).sort((a, b) => b.Hændelser - a.Hændelser);      
      if (result) {
        setData(result);
        console.log("result", result);
      }
    } else {
      const result = side10_3_fil_jsonData.sort((a, b) => b.Hændelser - a.Hændelser);      
      if (result) {
        setData(result);
        console.log("result", result);
        setDbantal(13);
      }      
    }
  };

 
  const handleHaendelserSort = () => {
    if (haendelserSortOrder === null) {
      setHaendelserSortOrder(true);
    } else if (haendelserSortOrder) {
      setHaendelserSortOrder(false);
    } else {
      setHaendelserSortOrder(null);
    }
    const sortedData = [...data].sort((a, b) => {
      if (haendelserSortOrder) {
        return a.Hændelser - b.Hændelser;
      } else {
        return b.Hændelser - a.Hændelser;
      }
    });
    setData(sortedData);
  };

  const handleOpmaerksomhedspunktSort = () => {
    if (opmaerksomhedspunktSortOrder === null) {
      setOpmaerksomhedspunktSortOrder(true);
    } else if (opmaerksomhedspunktSortOrder) {
      setOpmaerksomhedspunktSortOrder(false);
    } else {
      setOpmaerksomhedspunktSortOrder(null);
    }
    const sortedData = [...data].sort((a, b) => {
      const opmaerksomhedspunktA = a.Simplificering.toLowerCase();
      const opmaerksomhedspunktB = b.Simplificering.toLowerCase();
      if (opmaerksomhedspunktSortOrder) {
        return opmaerksomhedspunktA.localeCompare(opmaerksomhedspunktB);
      } else {
        return opmaerksomhedspunktB.localeCompare(opmaerksomhedspunktA);
      }
    });
    setData(sortedData);
  };

  const handleVaesentlighedSort = () => {
    if (vaesentlighedSortOrder === null) {
      setVaesentlighedSortOrder(true);
    } else if (vaesentlighedSortOrder) {
      setVaesentlighedSortOrder(false);
    } else {
      setVaesentlighedSortOrder(null);
    }
    const sortedData = [...data].sort((a, b) => {
      const vaesentlighedA = a.signifikanslabel.toLowerCase();
      const vaesentlighedB = b.signifikanslabel.toLowerCase();
      if (vaesentlighedSortOrder) {
        return vaesentlighedA.localeCompare(vaesentlighedB);
      } else {
        return vaesentlighedB.localeCompare(vaesentlighedA);
      }
    });
    setData(sortedData);
  };

  useEffect(() => {
    setAktivitet(queryParams.get("aktivitet"));
    setFokus(queryParams.get("fokus"));
    if (aktivitet !== null && data === null) {
      getAllData(dbtype);
    }
  }, [data, location.search, aktivitet, fokus, dbtype]);

  const columns = [
    {
      name: "Opmærksomhedspunkt",
      options: {
        filter: false,
        customHeadLabelRender: (columnMeta: {
          name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        }) => (
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {columnMeta.name}
          </Typography>
        ),
        customBodyRender: (value: {
          signifikanslabel: string;
          Simplificering: string;
        }) => {
          return (
            <a
              href={`/Side13_3/?aktivitet=${aktivitet}&vaesentlighed=${value.signifikanslabel}&opmaerksomhed=${value.Simplificering}`}
              style={{
                color: colors.dark,
                fontSize: "14px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "95%",
              }}
            >
              {value.Simplificering}
            </a>
          );
        },
      },
    },
    {
      name: "Væsentlighed",
      options: {
        filter: true,
        sort: true,       
        customHeadLabelRender: (columnMeta: {
          name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        }) => (
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {columnMeta.name}
          </Typography>
        ),
        customBodyRender: (value: string) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
    },
    {
      name: "Hændelser",
      options: {
        filter: true,
        sort: true,
        sortOrder: "asc",
        customHeadLabelRender: (columnMeta: {
          name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        }) => (
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {columnMeta.name}
          </Typography>
        ),
        customBodyRender: (value: number) => (
          <Typography variant="body2">
            {value} / {dbantal}
          </Typography>
        ),
      },
    },
    {
      name: "Relevans for projekt",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta: {
          name:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        }) => (
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {columnMeta.name}
          </Typography>
        ),
        customBodyRender: (value: number) => <Checkbox />,
      },
    },
  ];

  return (
    <Box sx={{ backgroundColor: colors.lightGrey }}>
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: colors.lightGrey,
          height: "100%",
        }}
      >
        {!data ? (
          <CircularProgress />
        ) : (
          <>
            <Grid
              container={true}
              sx={{
                border: "solid 1px #e0e6e8",
                padding: 4,
                bgcolor: colors.white,
                borderRadius: 4,
              }}
            >
              <Grid container>
                <Grid item xs={9} sx={{ mb: 8 }}>
                  <Typography variant="h2">
                    <div>{txttype} og {txtfokus}</div>
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: "20px" }}>
                    <p>
                      Her ser du et overblik over de miljøforhold, der indgår i
                      tidligere miljøvurderinger for {txttype}, med fokus på {txtfokus}.
                    </p>
                    <p>
                      Nedenfor er listet forskellige{" "}
                      <i>opmærksomhedspunkter</i>, som indgår i <b>{dbantal}</b>{" "}
                        tidligere miljøvurderingsrapporter omkring {txttype}.
                      Klik på opmærksomhedspunktet for at se de relevante miljøvurderingsrapporter.
                      <br/><i> Hændelser</i> angiver hvor mange gange, at det
                        pågældende opmærksomhedspunkt er blevet nævnt. Du kan sortere ved klik på kolonnehovedet.
                        <br />                        
                        <i> Væsentlighed</i> er ikke angivet, da de enkelte opmærksomhedspunkter kan have mange forskellige typer af væsentlighed
                        <br />
                        <i> Relevans for projekt </i> er ikke endnu implementeret.
                        
                    </p>
                  </Typography>
                </Grid>
                <Grid item xs={3} alignItems={"flex-end"}>
                  {/* <Link
                    to={`/side10_3?aktivitet=${aktivitet}`}
                    style={{
                      backgroundColor: colors.white,
                      color: colors.button,
                      border: `solid 1px ${colors.button}`,
                      padding: "10px 20px",
                      borderRadius: "4px",
                      textDecoration: "none",
                    }}
                  >
                    Rapport sammentælling
                  </Link> */}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                      title={""}
                      data={data.map((currentValue: any) => {
                        return [
                          {
                            signifikanslabel:
                              currentValue.signifikanslabel || "-",
                            Simplificering:
                              currentValue.Simplificering || "-",
                          },
                          currentValue.signifikanslabel || "-",
                          currentValue.Hændelser || "-",
                        ];
                      })}
                      columns={columns}
                      options={{ selectableRows: "none" }}
                    />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Box>
  );
};
export default Side10_3;
