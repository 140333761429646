import { FC } from "react";
import { AppBar, Box, Container, Divider, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../../shared/images/logo.png";
import { colors } from "../../../shared/const";

const navActiveStyle = {
  borderBottom: `solid 3px ${colors.success}`,
  color: colors.white,
  textDecoration: "none",
  paddingBottom: "23px",
  marginRight: "25px",
};

const navStyle = {
  borderBottom: `solid 3px transparent`,
  color: colors.lightGrey400,
  textDecoration: "none",
  paddingBottom: "23px",
  marginRight: "25px",
  "&:hover": {
    color: colors.white,
    backgroundColor: colors.white,
  },
};

const Header: FC = () => {
  const location = useLocation();

  return (
    <AppBar position="static">
      <Container maxWidth="lg" sx={{ backgroundColor: colors.primary }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                color: colors.white,
                textDecoration: "none",
              }}
            >
              CAUSA
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: colors.grey }}
            />
            <Box sx={{ width: "30%", ml: "16px" }}>
              <img src={Logo} alt="Dreams Causa" style={{ width: "100%" }} />
            </Box>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ backgroundColor: colors.primary }}>
        <Box>
          <NavLink
            to="/"
            style={({ isActive }) =>
              isActive ||
              location.pathname === "/side10" ||
              location.pathname === "/side13"
                ? navActiveStyle
                : navStyle
            }
          >
            Introduktion
          </NavLink>
          <NavLink
            to="/search"
            style={({ isActive }) =>
              isActive || location.pathname === "/search"
                ? navActiveStyle
                : navStyle
            }
          >
            Søgning i CAUSA
          </NavLink>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
