import React, { FC, useEffect, useState } from "react";
import { Chip, styled } from "@mui/material";
import {
  Box,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Button from '@mui/material/Button';
import { useNavigate  } from 'react-router-dom';

import { colors } from "../../shared/const";
import { InfoOutlined } from "@mui/icons-material";

// import { supabase } from "../../supabaseClient";

export const BadgesBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive?: boolean;
}>(({ isActive = false }) => ({
  opacity: isActive ? 1 : 0.5,
  userSelect: isActive ? "auto" : "none",
  cursor: isActive ? "auto" : "default",
}));

const chipsData = [
  "Befolkningen",
  "Biologisk mangfoldighed",
  "Landskab",
  "Vand",
  "Luft",
  "Menneskers sundhed",
  "Jordbund",
  "Jordarealer",
  "Flora og fauna",
  "Kulturarv",
  "Katastroferisici og ulykker",
  "Meterielle goder",
  "Klimatiske faktorer",
  "Ressourceeffktivitet",
];



const SearchPage: FC = () => {
  const [data, setData] = useState<any>(null);
  const [aktivitet, setFirstSelectValue] = React.useState("");
  const [fokus, setSecondSelectValue] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const projectTypes = [
    { key: 1, name: 'https://dreams.wikibase.cloud/entity/Q702', subjektlabel: 'Solcelleprojekt' },
    { key: 2, name: 'https://dreams.wikibase.cloud/entity/Q5580', subjektlabel: 'Elinfrastrukturprojekt' },
    // Add more values here in the future
  ];

  const fokusTypes = [
    { key: 1, name: 'Natur', fokuslabel: 'Natur' },
    { key: 2, name: 'Sundhed', fokuslabel: 'Sundhed' },  
  ];

  const [uniqueFirstSelect, setUniqueFirstSelect] = useState<string[]>([]);
  const [uniqueSecondSelect, setUniqueSecondSelect] = useState<string[]>([]);
    const [uniqueSubject, setUniqueSubject] = useState<string[]>([]);


  // const handleChange = (event: SelectChangeEvent) => {
  //   setFirstSelectValue(event.target.value);
  // };

  const handleChangeFirst = (event: SelectChangeEvent) => {
    console.log('Selected value:', event.target);
    console.log('Selected value:', event.target.value);    
  //  getSubjectData(event.target.value);
    setFirstSelectValue(event.target.value);
    setIsButtonDisabled(false);
  };

  const handleChangeSecond = (event: SelectChangeEvent) => {
    setSecondSelectValue(event.target.value);
    setIsButtonDisabled(false);
  };

  // const getAllData = async () => {
  //   const result = await supabase.from("dreams_sol_el").select();
  //   if (result) {
  //     setData(result.data);
  //     if (result.data) {
  //       // const labelsFirst = result.data.map((item) => item.subjektlabel);
  //       // // @ts-ignore
  //       // setUniqueFirstSelect([...new Set(labelsFirst)]);

  //       // const labelsSecond = result.data.map((item) => item["påvirkningLabel"]);
  //       // // @ts-ignore
  //       // setUniqueSecondSelect([...new Set(labelsSecond)]);
  //     }
  //   }
  //   console.log(result.data);
  // };

  // const getSubjectData = async (projectType: string) => {
  //   var lookuptype = "";
  //   if (!projectType) return;
  //   else if (projectType === "Solcelleprojekt") {
  //     lookuptype= "https://dreams.wikibase.cloud/entity/Q702"
  //   } else if (projectType === "Elinfrastrukturprojekt") {
  //     lookuptype= "https://dreams.wikibase.cloud/entity/Q5580"
  //   }
  //   const result = await supabase
  //     .from('dreams_sol_el')
  //     .select()
  //     .eq('projectType', lookuptype)
  //     .order('recipientLabel', { ascending: true });
  //   console.log("getSubjectData is called with projectType = "+lookuptype+". Result is: " + result);
  //   console.log(result.data);
  //   if (result) {
  //     setData(result.data);
  //     if (result.data) {
  //       const labelsSecond = result.data.map((item) => item["recipientLabel"]);
  //       console.log("getSubjectData is called. LabesSecond is set: " + labelsSecond);

  //      // @ts-ignore
  //       setUniqueSecondSelect([...new Set(labelsSecond)]);
  //     }
  //   }
  // };

const navigate = useNavigate();

const handleButtonClick = () => {
   navigate(`/side10_3?aktivitet=${aktivitet}&fokus=${fokus}`);
};


  useEffect(() => {
    if (!data) {
      setData(true);
    }
  }, [data]);

  return (
    <Box sx={{ backgroundColor: colors.lightGrey }}>
      <Container
        maxWidth="lg"
        sx={{ backgroundColor: colors.lightGrey, height: "100%" }}
      >
        <Grid
          spacing={2}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item xs={7}>
            <Card variant="outlined" sx={{ p: 2, height: "100%" }}>
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", mb: 3 }}
              >
                <InfoOutlined sx={{ fill: colors.primaryLight, mr: 1 }} />
                Information
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ fontSize: "16px" }}
              >
                CAUSA viser et overblik over miljøpåvirkninger i tidligere
                rapporter udarbejdet indenfor typer af projekter og planer.
                Her kan du søge på aktivitetstype og fokusområde. 

              </Typography>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", mb: 3 }}
              >
                Angiv projekttype & fokusområde
              </Typography>
              {data ? (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="first-label">
                      Vælg aktivitetstype
                    </InputLabel>

                    <Select
                      sx={{
                        backgroundColor: colors.white,
                      }}
                      labelId="first-label"
                      id="first-select"
                      value={aktivitet}
                      label="Vælg aktivitetstype for at begynde"
                      onChange={handleChangeFirst}>
                      <MenuItem value="Solcelleprojekt">
                        Solcelleprojekt
                      </MenuItem>
                      <MenuItem value="Elinfrastrukturprojekt">
                        Elinfrastrukturprojekt
                      </MenuItem>              
                  </Select>                    
                  </FormControl>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="second-label">Vælg fokusområde</InputLabel>
                    <Select
                      sx={{
                        backgroundColor: colors.white,
                      }}
                      labelId="second-label"
                      id="second-select"
                      value={fokus}
                      label="Vælg fokusområde"
                      onChange={handleChangeSecond}
                    >
                      <MenuItem value="Natur">
                        Natur
                      </MenuItem>
                      <MenuItem value="Sundhed">
                        Sundhed
                      </MenuItem>                      
                    </Select>
                  </FormControl>
                </>
              ) : (
                <CircularProgress />
              )}
            </Card>
          </Grid>
        </Grid>
        <BadgesBox isActive={!!aktivitet && !!fokus}>
          <Card variant="outlined" sx={{ mt: 2 }}>
            <Grid
              spacing={0}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              wrap="nowrap"
            >
              <Grid
                sx={{ p: 2, borderRight: `solid 1px ${colors.dividerBorder}` }}
                item
                xs={6}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", mb: 3 }}
                >
                  <InfoOutlined sx={{ fill: colors.primaryLight, mr: 1 }} />
                  Information
                </Typography>
           
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {chipsData.map((chip) => (
                    <Chip
                      label={chip}
                      color={"primary"}
                      variant="outlined"
                      sx={{ borderRadius: "5px", mb: 2, mr: 2 }}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid sx={{ p: 2 }} item xs={6}>
                {/* <Typography>Vælg miljøbegreb for at begynde</Typography> */}
                
                <Button variant="contained" color="info" onClick={handleButtonClick} disabled={isButtonDisabled}>
                  Fortsæt
                </Button>


              </Grid>
            </Grid>
          </Card>
        </BadgesBox>
      </Container>
    </Box>
  );
};
export default SearchPage;
