import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { supabase } from "./supabaseClient";

// async function getAllData() {
//   // const dreams_projecttype_q702 = await supabase.from('dreams_projecttype_q702').select()
//   // console.log(dreams_projecttype_q702)
//   const dreams_projecttype_q5580 = await supabase
//     .from("dreams_projecttype_q5580")
//     .select();
//   console.log(dreams_projecttype_q5580);
// }
// getAllData();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
