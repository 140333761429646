import React, { FC, useEffect, useState } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Stack,
  styled,
  Typography,
  Box,
  Container,
} from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";
import { colors } from "../../shared/const";
import { side10_2_jsonData } from "../../shared/data";
import { useLocation } from 'react-router-dom';

export const BadgesBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive?: boolean;
}>(({ isActive = false }) => ({
  opacity: isActive ? 1 : 0.5,
  userSelect: isActive ? "auto" : "none",
  cursor: isActive ? "auto" : "default",
}));

const Side10_2: FC = () => {
  const [data, setData] = useState<any>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [aktivitet, setAktivitet] = useState<string | null>(null);
  const [dbantal, setDbantal] = useState<any>(null);
  const [haendelserSortOrder, setHaendelserSortOrder] = useState<null | boolean>(true); 
  const [opmaerksomhedspunktSortOrder, setOpmaerksomhedspunktSortOrder] = useState<null | boolean>(null); 

  let dbtype = "";
  let txttype = "Alle projekttyper";
  if (aktivitet) {
    if (aktivitet === "Solcelleprojekt") {
      dbtype = "q5580";
      txttype = "Solcelleprojekter";
    } else if (aktivitet === "Elinfrastrukturprojekt") {
      dbtype = "q702";
      txttype = "Elinfrastrukturprojekter";
  //    setDbantal(4);
    } else if (aktivitet.includes("(under udarbejdelse)")) {
      dbtype = "ikke klar";
      txttype = "Projekt " + aktivitet;
    }
  }

  const getAllData = async (dbtype: string | undefined) => {
    if (dbtype) {
      if (aktivitet === "Solcelleprojekt") {
        setDbantal(9);
      } else if (aktivitet === "Elinfrastrukturprojekt") {
        setDbantal(4);
      }  
      const result = side10_2_jsonData.filter(item => 
        item["type"] === dbtype         
      ).sort((a, b) => b.Hændelser - a.Hændelser);      
      if (result) {
        setData(result);
        console.log("result", result);
      }
    } else {
      const result = side10_2_jsonData.sort((a, b) => b.Hændelser - a.Hændelser);      
      if (result) {
        setData(result);
        console.log("result", result);
        setDbantal(13);
      }      
    }
  }; 
  
  const handleHaendelserSort = () => {
    if (haendelserSortOrder === null) { 
      setHaendelserSortOrder(true);
    } else if (haendelserSortOrder) { 
      setHaendelserSortOrder(false);
    } else { 
      setHaendelserSortOrder(null);
    }
    const sortedData = [...data].sort((a, b) => {
      if (haendelserSortOrder) {
        return a.Hændelser - b.Hændelser;
      } else {
        return b.Hændelser - a.Hændelser;
      }
    });
    setData(sortedData);
  };

  const handleOpmaerksomhedspunktSort = () => {
    if (opmaerksomhedspunktSortOrder === null) { 
      setOpmaerksomhedspunktSortOrder(true);
    } else if (opmaerksomhedspunktSortOrder) { 
      setOpmaerksomhedspunktSortOrder(false);
    } else { 
      setOpmaerksomhedspunktSortOrder(null);
    }
    const sortedData = [...data].sort((a, b) => {
      const opmaerksomhedspunktA = a.påvirkninglabel.toLowerCase();
      const opmaerksomhedspunktB = b.påvirkninglabel.toLowerCase();
      if (opmaerksomhedspunktSortOrder) {
        return opmaerksomhedspunktA.localeCompare(opmaerksomhedspunktB);
      } else {
        return opmaerksomhedspunktB.localeCompare(opmaerksomhedspunktA);
      }
    });
    setData(sortedData);
  };  


  
    useEffect(() => {
    setAktivitet(queryParams.get('aktivitet'));
    if (aktivitet !== null && data === null) {
      getAllData(dbtype);
    }
  }, [data, location.search, aktivitet, dbtype]);



  return (
    <Box sx={{ backgroundColor: colors.lightGrey }}>
      <Container maxWidth="lg" sx={{ backgroundColor: colors.lightGrey, height: "100%" }}>
        
        {!data ? (
          <CircularProgress />
        ) : (
          <>
              <Grid container>
                <Grid item xs={12} sx={{ mb: 8 }}>
                  <Typography variant="h2">                                        
                       <div>{txttype} - Rapport sammentælling</div>                    
                  </Typography>
                  <Typography variant="h4" style={{ marginTop: '20px' }}>
                    <p>
                      Her ser du et overblik over de miljøforhold, der indgår i tidligere miljøvurderinger for {txttype}.
                    </p>
                    <p>
                    Nedenfor er listet {dbantal} forskellige <i>opmærksomhedspunkter</i>, som indgår i de tidligere miljøvurderinger. 
                      <i> Hændelser</i> angiver antal rapporter hvori det pågældende opmærksomhedspunkt er blevet nævnt.
                      {/* <a href={`/side10?aktivitet=${aktivitet}`}> Se alle hændelser</a> */}
                    </p>
                   </Typography>
                </Grid>
                <Grid item xs={6} sx={{ mb: 2 }}>
                  

                  <Typography component={"h5"}
                   sx={{ 
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={handleOpmaerksomhedspunktSort}>
                    Opmærksomhedspunkt
                    <span>
                      {opmaerksomhedspunktSortOrder === null ? '' : opmaerksomhedspunktSortOrder ? ' ▲' : ' ▼'}
                    </span>
                  
                </Typography>{" "}
              </Grid>
              
              <Grid item xs={4} sx={{ mb: 2 }}>
                  <Typography
                    component={"h5"}
                    sx={{ 
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={handleHaendelserSort}>
                    Hændelser
                    <span>
                      {haendelserSortOrder === null ? '' : haendelserSortOrder ? ' ▲' : ' ▼'}
                    </span>
                  </Typography>
              </Grid>
              
                
                
                

              <Stack spacing={2} sx={{ width: "100%" }}>
                {data.map(
                  (i: {
                    påvirkninglabel: string;
                    signifikanslabel: string;
                    Hændelser: number;
                  }, index: number) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{ p: 2, display: "flex", alignItems: "center" }}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >                       
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >                         
                          <Box sx={{display: "flex", alignItems: "center",}}>                            
                           
                              {i.påvirkninglabel}
                           
                          </Box>
                        </Box>
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Typography variant="body2">                          
                          {i.Hændelser} / {dbantal}
                        </Typography>{" "}
                      </Grid>
                    </Card>
                  )
                )}
              </Stack>
            </Grid>
          </>
        )}
      </Container>
    </Box>
  );
};
export default Side10_2;
